import { Header, Icon, Stack } from '@asgard/ui-library';
import { Page } from '@components/layout/Page';
import { faRobot } from '@fortawesome/pro-solid-svg-icons';
import { useI18n } from '@hooks/useI18n';
import { getTranslations } from '@utils/i18n';
import { GetStaticProps, NextPage } from 'next';

const CustomError: NextPage = () => {
  const { t } = useI18n('common');
  return (
    <Page user={null} pageId="404">
      <Stack alignment="center" direction="column">
        <Header as="h2" size="3xl">
          {t('common.page_404.title')}
        </Header>

        <Icon icon={faRobot} size="7xl" />

        <p>{t('common.page_404.desc')}</p>
      </Stack>
    </Page>
  );
};

export default CustomError;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const translations = await getTranslations(['common'], locale);

  return {
    props: {
      ...translations
    }
  };
};
